.footer {
    margin-top: 40px;
    border-top: 1px solid #eaeaea;
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f0f2f4;
    color: #637588;
    font-size: 12px;
    padding: 0 20px;
    box-sizing: border-box;
}

@media only screen and (max-width: 768px) {
    .footer {
        flex-direction: column;
        height: auto;
        text-align: center;
        padding: 10px 20px;
    }

    .leftSection, .rightSection {
        padding: 5px 0;
    }
}

.leftSection {
    padding-left: 0;
}

.rightSection {
    padding-right: 0;
}
