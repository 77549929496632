.container {
    display: flex;
    height: 100%;
    padding: 20px;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 40px;
}

.content {
    flex: 1;
    margin-left: 50px;
}

.gridTitle {
    font-size: 28px;
    margin-bottom: 20px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.015em;
}

.gridTitle span {
    color: #A0A0A0;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
    /* grid-gap: 20px; */

}

.skeletonContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .skeletonItem {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .skeletonText {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .container {
        flex-direction: column;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 20px;
        padding: 10px;
    }

    .content {
        margin-left: 0;
        margin-top: 20px; /* Space between the title and the menu */
    }

    .gridTitle {
        font-size: 24px; /* Slightly smaller for tablet-sized screens */
        line-height: 32px;
        text-align: center;
        margin-bottom: 15px;
    }

    .gridContainer {
        grid-template-columns: 1fr; /* Stack items in one column */
        grid-gap: 15px; /* Slightly reduced gap for smaller screens */
    }
}

@media screen and (max-width: 480px) {
    .gridTitle {
        font-size: 20px; /* Further reduction for small screens */
        line-height: 28px;
        margin-bottom: 10px;
    }

    .container {
        margin-left: 10px;
        margin-right: 10px;
        padding: 10px;
        padding-top: 0; /* Remove top padding to fit better on small screens */
    }

    .content {
        margin-top: 15px;
    }

    .gridContainer {
        grid-template-columns: 1fr; /* Ensure grid items stack in one column on small screens */
        grid-gap: 10px; /* Further reduced gap for very small screens */
    }
}
