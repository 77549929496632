.signedBox {
    display: inline-block;
    border-radius: 20px;
    width: 100px;
    text-align: center;
    padding: 8px;
    border: 1px solid transparent;
    background-color: transparent;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 12.1px;
    letter-spacing: -0.03em;
}

.signedBox.signed {
    color: #348D58; /* Green color */
    border-color: #A7DEBB;
    box-shadow: 0px 1px 2px 0px rgba(64, 141, 72, 0.2);
}

.signedBox.unsigned {
    color: #818181; /* Gray color */
    border-color: #CAC8C8;
    box-shadow: 0px 1px 2px 0px rgba(64, 95, 141, 0.15);

}
