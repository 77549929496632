.sidebar {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin-right: 20px;
  }
  
  .sidebarItem {
    display: flex;
    align-items: center;
    padding: 6px 20px;
    margin-bottom: 10px;
    border: none;
    border-radius: 12px;
    text-align: left;
    cursor: pointer;
    font-size: 16px;
    color: #333;
    transition: all 0.3s ease;
    background-color: #fff;
  }
  .containerIcon{
    background-color: #EDEDED;
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    color: #ABAFB4;
    font-size: 11px;
  }


  .containerIcon span{
    font-size: 18px;
    color: #ABAFB4;
  }
  .sidebarItem:hover,
  .sidebarItem.active {
    background-color: #F7F7FF;
    color: #CAD5F9;
    border: 1px solid #CAD5F9;
  }
  
  .sidebarItem.active .containerIcon {
    background-color: #CAD5F9;
  }
  
  .sidebarItem.active span {
    color: #fff;
  }

  .menuText {
    color: #323232;
    padding-left: 10px;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.03em;
    font-weight: 500;
  }
  
  @media screen and (max-width: 480px) {
    .sidebar {
      width: 100%;
      margin-right: 0;
    }
  }