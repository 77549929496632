.header {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  padding-left: 80px;
}

.imgContainer {
  height: 230px;
  width: auto;
  margin-right: 20px;
}

.productImage {
  width: auto;
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  border-radius: 5px;
}

.filters {
  padding-top: 30px;
}

.titleContainer {
  margin-top: 15px;
}

.mainTitle {
  font-size: 42px;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: -0.05em;
  padding-bottom: 3px;
  color: #333333;
  max-width: 396px;
}

.subTitle {
  font-size: 20px;
  font-weight: 200;
  line-height: 28px;
  letter-spacing: -0.015em;
  color: #272727;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  .imgContainer {
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%;
    height: auto;
  }

  .productImage {
    height: auto;
    width: 80%;
    max-width: 200px;
  }

  .mainTitle {
    font-size: 32px;
    line-height: 42px;
    max-width: 100%;
  }

  .subTitle {
    font-size: 16px;
    line-height: 24px;
  }

  .filters {
    padding-top: 20px;
  }
}
