/* ListingGrid.module.css */

.outerContainer {
  display: flex;
  flex-direction: column;
  /* padding: 10px; */
}

.header {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr;

  font-weight: bold;  
  border-top: 5px solid #F0F0F0;
  border-bottom: 5px solid #F0F0F0;
  margin-bottom: 10px;
}

.headerItem {
  padding-left: 10px;
  padding-bottom: 20px;
  padding-right: 30px;
  padding-top: 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #272727;
}

.listingsContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr;
  gap: 12px;
}



.listingBox {
  display: contents;

}

.imageColumn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
}

.listingBox > div {
  /* border-bottom: 1px solid #ccc; */
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 30px;
  text-align: left;
  align-content: center;
}

.price {
  font-weight: bold;
}

.signed {
  font-style: italic;
}

/* Mobile Styles */
.outerContainerMobile {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.listingBoxMobile {
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.listingBoxMobile img {
  max-width: 100%;
  height: auto;
}

.listingBoxMobile div {
  margin-top: 10px;
  text-align: center;
}
.containerInfo{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}