.outerContainer {
    justify-content: center;
    height: 100%;
    align-items: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    overflow-y: auto; /* Enable scrolling if content overflows */
    /* padding: 0 20px;  */
}

.page {
    /* margin-top: 20px; */
    /* padding-top: 60px; */
    /* padding-left: 20px; */
    /* padding-right: 20px; */
    width: 100%;
    /* max-width: 1200px;  */
    min-height: 100vh;
    box-sizing: border-box;
}

@media only screen and (max-width: 768px) {
    .page {
        padding-top: 50px;
        margin-left: 10px;
        margin-right: 10px;
    }
}

@media only screen and (max-width: 480px) {
    .page {
        padding-top: 40px;
        margin-left: 5px;
        margin-right: 5px;
    }
}
