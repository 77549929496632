.filter {
}

.filterLabel {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #272727;
  padding-right: 10px;
}

.filterSelect {
  border: 0.8px solid #27272726;
  border-radius: 10px;
  padding: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.015em;
  text-align: left;
  box-shadow: 0px 1px 3px 0px #241A5E1A;
}

.filterSelect:focus {
  outline: none;
}

.filterOption {
  background-color: #fff;
  /* color: #272727; */
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .filter{
    display: flex;
    flex-direction: column;
  }
  .filterLabel {
    font-size: 14px;
  }

  .filterSelect {
    font-size: 12px;
  }
  
}