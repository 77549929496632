/* SalesChart.module.css */
.chartContainer {
    width: 100%;
    height: 300px; 
  }
  
  .canvasFullWidth {
    width: 100% !important;
    height: auto !important; 
  }
   