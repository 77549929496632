.topSection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-top: 120px;

  padding-bottom: 50px;
  
  background-color: #FAFBFD;
  border-bottom: 1px solid #E3E4EA;
}
.chartSection {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 60px;
  padding-bottom: 80px;
  border-bottom: 1px solid #E3E4EA;
}
.chartTitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.03em;
  color: #272727;
  margin-bottom: 30px;

}
.listingsSection{
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;

}
.listingsTitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.03em;
  color: #272727;
  padding-bottom: 30px;
}

@media screen and (max-width: 768px) {

  .chartSection {
    padding-left: 20px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 40px;
  }
  .chartTitle {
    font-size: 20px;
    margin-bottom: 20px;
  }
  
}