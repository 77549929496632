.websiteBox {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 20px;
    background: #fff;
    border: 1px solid #e0e0e0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    cursor: pointer;
  }
  
  .websiteBox:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .websiteBox a {
    text-decoration: none;
    color: #363636;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.015em;
    display: flex;
    align-items: center;
  }
  
  .arrow {
    margin-left: 8px;
    transition: margin-left 0.3s ease;
  }
  
  .websiteBox:hover .arrow {
    margin-left: 12px;
  }
  