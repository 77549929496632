.titleContainer {
    /* color: #000; */
    margin-top: 15px;
  }
  
  .mainTitle {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.2;
    padding-bottom: 3px;
    color: #111418
  }
  
  .subTitle {
    font-size: 16px;
    font-weight: 200;
    line-height: 1.2;
    color: #637588
  }
  