.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 600px;
    background-color: #fff;
    text-align: center;
    font-family: 'Inter', sans-serif;
    padding-top: 60px;
    border-bottom: #E3E4EA 0.5px solid;
    padding: 20px;
}

.subtitle {
    color: #414FD2;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
    letter-spacing: -0.015em;
}

.title {
    color: #333333;
    font-weight: 600;
    font-size: 68px;
    line-height: 80px;
    letter-spacing: -0.05em;
    margin-bottom: 20px;
    max-width: 850px;
}

.description {
    color: #656565;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.03em;
    margin-bottom: 50px;
    max-width: 700px;
}

.button {
    padding: 12px 24px;
    background-color: #414FD2;
    color: #FFF;
    border: none;
    border-radius: 40px;
    font-size: 15px;
    cursor: pointer;
    margin-bottom: 50px;
    letter-spacing: -0.015em;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .container {
        height: auto; /* Allow the height to adjust based on content */
        padding-top: 40px; /* Adjust padding for smaller screens */
    }

    .title {
        font-size: 48px; /* Smaller title for medium screens like tablets */
        line-height: 60px;
        max-width: 100%; /* Ensure it fits within the screen width */
    }

    .description {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 30px;
        max-width: 100%; /* Ensure it fits within the screen width */
    }

    .button {
        padding: 10px 20px;
        font-size: 14px; /* Slightly smaller button for medium screens */
    }
}

@media screen and (max-width: 480px) {
    .container {
        padding-top: 40px; /* Further adjust padding for small screens */
    }

    .subtitle {
        font-size: 14px;
        margin-bottom: 15px;
       
    }

    .title {
        font-size: 28px; /* Smaller title for small screens */
        line-height: 33px;
        padding-left: 10px;
    }

    .description {
        font-size: 12px;
        line-height: 22px;
        margin-bottom: 20px;
        
    }

    .button {
        padding: 8px 18px;
        font-size: 14px;
        margin-bottom: 40px;
    }
}
