  .containerGridItem{
    border-bottom: 1px solid #e0e0e0;
  }


  .gridItem {
    overflow: hidden;
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
    
    /* flex-direction: column; */
  }

  
  .bookImage {
    height: 200px; 
    object-fit: contain; 
    background-color: #fff;
    border-radius: 5px;

  }
  .bookInfo{
    margin-top: 20px;

    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
  }
  .bookTitle {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.015em;
    color: #333;

  }

  .bookSubtitle{
    margin-top: 12px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.015em;
    color: #272727;
  }

  .gridItem:hover {
    cursor: pointer; /* Changes the cursor to a hand icon */
    transform: scale(1.05); /* Slightly enlarges the image on hover */
}

