.containerImageCarousel {
  position: relative;
  width: 100%;
  background-color: #f0f0ff;
  border-radius: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.listingImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  padding: 10px;
}

.arrowButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: black;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  transition: background-color 0.3s ease;
}

.arrowButton:hover {
  background-color: #e0e0e0;
}

.arrowButton:focus {
  outline: none;
}

.arrowButton:first-of-type {
  left: 10px;
}

.arrowButton:last-of-type {
  right: 10px;
}

.arrowButton > span {
  font-size: 18px;
  line-height: 18px;
}
