.topBar {
    height: 50px;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
}

.linkImg {
    height: 40%;
    width: auto;
    margin-bottom: 0;
    margin-top: 8px;
    padding: 0;
}

.logo {
    height: 80%;
    width: auto;
    margin-left: 20px;
}

@media only screen and (max-width: 768px) {
    .logo {
        height: 60%;
        margin-left: 10px;
    }
}
